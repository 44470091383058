import React, { useEffect } from "react";
import Title from "../core/Title";
import { useTranslation } from "react-i18next";

import MainBtn from "../core/MainBtn";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { request } from "../../utils/axios";
import Spinner from "../core/Spinner";
import WhatsAppIcon from "../core/WhatsAppIcon";
import SocialIcon from "./SocialIcon";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const fetchData = () => {
    const url = "/themes/home";
    return request({ url });
  };
  const { data, isError: isErrorImage } = useQuery("getHeroImage", fetchData);

  const fetchText = () => {
    const url = "/ContentText/home/*";
    return request({ url });
  };

  const {
    data: dataText,

    isLoading: textLoading,
    isError: isErrorText,
  } = useQuery("getHomeText", fetchText);

  if (textLoading) {
    return <Spinner />;
  }

  if (isErrorText) {
    return <p>Error: {isErrorText.message}</p>;
  }
  if (isErrorImage) {
    return <p>Error loading image: {data.error.message}</p>;
  }

  return (
    <div
      data-aos="fade-left"
      className="lg:px-12 flex flex-col md:flex-row justify-center md:items-start items-center gap-x-4"
    >
      <div className="md:hidden flex justify-center my-8">
        <SocialIcon />
      </div>
      <div className="text-mainpage flex flex-col items-center justify-around w-[100%] mx-auto mb-2 md:mb-0 md:w-[100%] mt-10 lg:my-[15%] ">
        <Title text={t("hero title")} hasAnimate={true} />
        <p className="w-[90%] md:w-[70%] lg:w-[50%] leading-[30px] mx-auto mt-4 text-center text-[14px] sm:text-sm md:text-base text-white">
          {/* {t("description")} */}
          {i18n.language === "ar"
            ? dataText?.data?.data?.[0]?.content_ar || "لا يوجد محتوي"
            : dataText?.data?.data?.[0]?.content_en || "there is no content"}
        </p>
        <MainBtn text={t("explore")} action={() => navigate("/courses")} />
      </div>

      {/* <img
        data-aos={isArabic ? "fade-right" : "fade-left"}
        src={data?.data?.data?.[0]?.image}
        alt={data?.data?.data?.[0]?.image_name}
        className=" rounded-xl w-[50%]"
      /> */}
      <WhatsAppIcon />
    </div>
  );
};

export default Hero;

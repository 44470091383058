import React, { useEffect } from "react";
import Aos from "aos";
import Title from "../core/Title";

import image1 from "../../../assets/hero-section/close-up-people-learning-work.webp";
import image2 from "../../../assets/hero-section/pexels-pixabay-355948.webp";
import image3 from "../../../assets/hero-section/pexels-olly-3807755.webp";
import image4 from "../../../assets/hero-section/pexels-ekrulila-2292837.webp";
import { useTranslation } from "react-i18next";

const cards = [
  {
    name: "cardhometitle1",
    image: image1,
    praghrap: "cardHomeP1",
    anim: "fade-right",
  },
  {
    name: "cardhometitle2",
    image: image2,
    praghrap: "cardHomeP2",
    anim: "fade-left",
  },
  {
    name: "cardhometitle3",
    image: image3,
    praghrap: "cardHomeP3",
    anim: "fade-right",
  },
  {
    name: "cardhometitle4",
    image: image4,
    praghrap: "cardHomeP4",
    anim: "fade-left",
  },
];

export default function CardHomePage() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div className="container mx-auto">
        <div className=" mx-auto">
          <div className="w-[80%] text-center mx-auto my-4">
            <Title
              text={t("herotitlecardpage")}
              items="items-center"
            />
          </div>

          <div className="cards-homepage mx-auto grid md:grid-cols-2 gap-4 overflow-hidden">
            {cards.map((item, index) => (
              <div
                className="w-[80%]  mx-auto "
                key={index}
                data-aos={item.anim}
              >
                <div
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${item.image}) `,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="card block h-[160px] p-6 rounded-lg  xl:h-[200px]"
                >
                  <h5 className="mb-2 text-center align text-2xl font-bold tracking-tight text-white">
                    {t(item.name)}
                  </h5>

                  <p className="-my-1 text-center text-[16px] text-white md:mt-5 lg:mt-16">
                    {t(item.praghrap)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  subscribeToCourse,
  userLogin,
  userLogout,
} from "./authActions";

// initialize tsdUserToken from local storage
const tsdUserToken = localStorage.getItem("tsdUserToken")
  ? localStorage.getItem("tsdUserToken")
  : null;

const initialState = {
  loading: false,
  userInfo: {},
  tsdUserToken: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: (builder) => {},

  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.tsdUserToken = payload.tsdUserToken;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // register user
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // logout user
    builder
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.loading = false;
        state.userInfo = null; // Reset user information
        state.tsdUserToken = null; // Reset user token
      })
      .addCase(userLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // subscripe to course
    builder.addCase(subscribeToCourse.rejected, (state, action) => {
      console.log("Error occurred:", action.payload);
      state.loading = false;
      state.success = false;
      state.courseId = null;

      if (action.payload && action.payload.status === "حدث خطأ ما !") {
        // Consider this as an error
        state.error = action.payload;
      } else {
        // Handle other types of errors
        state.error = {
          status: "Unknown error",
          message: "An unknown error occurred.",
        };
      }
    });
  },
});

export default authSlice.reducer;

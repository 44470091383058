import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const ImageHero = ({ img, desc, title }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="flip-up" className="flex justify-center relative mb-6">
      {/* Image */}
      <img
        src={img}
        alt=""
        className="w-full md:w-[90%] max-h-[550px] rounded-lg object-cover"
      />

      <div className="absolute top-0 md:left-[5%] w-full md:w-[90%] h-full bg-black bg-opacity-50 flex justify-center items-center rounded-lg">
        <div className=" flex flex-col items-center text-center">
          <span className="text-3xl md:text-6xl font-bold text-[#0298b1] mb-2">
            {title}
          </span>
          <p className="mt-2 w-[90%] md:w-[60%] text-white text-[12px] md:text-[18px]">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageHero;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa6";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../core/Spinner";

const SelectCollege = ({
  collegesData,
  isLoadingColleges,
  onSelectCollege,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpen(false);
    onSelectCollege(option.id);
    // console.log("college id:", option.id);
  };

  return (
    <div className="mb-2">
      <div
        className="w-[290px] md:w-[360px] border border-[#c8c7cc] rounded-lg  py-3 px-3  flex justify-between items-center"
        onClick={handleOpen}
      >
        {selectedOption
          ? i18n === "en"
            ? selectedOption?.college_en
            : selectedOption?.college_ar
          : t("select an college")}
        <FaAngleDown className="text-[#2180a6]" />
      </div>
      {open && (
        <div className=" max-h-52 overflow-y-scroll w-[290px] md:w-[360px] shadow rounded-lg py-3 px-3 my-[2px]">
          {isLoadingColleges ? (
            <Spinner />
          ) : (
            collegesData.map((city) => (
              <div
                key={city?.id}
                onClick={() => handleOptionClick(city)}
                className="cursor-pointer hover:bg-gray-200 py-1"
              >
                {i18n === "en" ? city?.college_en : city?.college_ar}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SelectCollege;

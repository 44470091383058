import React, { useEffect, useState } from "react";
import AboutUsSample from "../components/core/AboutUsSample";
import { useTranslation } from "react-i18next";
import { Arrow } from "../../assets/DataIndex";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { useQuery } from "react-query";
import { request } from "../utils/axios";
import Title from "../components/core/Title";
import Spinner from "../components/core/Spinner";
const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  useEffect(() => {
    AOS.init();
  }, []);
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/QA?lang=${i18n.language || lang}`;
    return request({ url });
  };

  const { isLoading, data, refetch, isError } = useQuery("getFQ", fetchData, {
    onSuccess() {
      // setQuestions(data?.data?.data);
      // console.log(data?.data?.data);
    },
    onError(e) {
      console.log(e.message);
    },
  });

  const [open, setOpen] = useState(null);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <h1>there is error in fetch data </h1>;
  }

  return (
    <div className="px-8 md:px-16">
      <AboutUsSample />

      <div className="mt-6 md:mt-12 relative ">
        <div className="my-12">
          <Title text={t("common questions")} items="items-center" />
        </div>

        {Array.isArray(data?.data?.data) &&
          data?.data?.data?.map((q) => {
            // console.log(q.id);
            return (
              <div>
                <div
                  key={q.id}
                  className="flex items-center justify-between cursor-pointer mb-3"
                  onClick={() =>
                    setOpen((prev) => (prev === q?.id ? null : q?.id))
                  }
                >
                  <div className="flex items-center gap-x-1">
                    <img
                      src={Arrow}
                      alt="arrow"
                      className={`${isEnglish ? "rotate-180" : null}`}
                    />
                    <span className="text-[#0298b1] font-bold text-lg md:text-xl">
                      {t(q?.question)}
                    </span>
                  </div>

                  {open === q?.id ? (
                    <FaAngleDoubleUp size={30} className="text-[#0298b1]" />
                  ) : (
                    <FaAngleDoubleDown size={30} className="text-[#0298b1]" />
                  )}
                </div>

                {open === q?.id && (
                  <div className=" border-lg p-2">
                    <span data-aos="zoom-in" className="w-full  text-AboutUs">
                      {t(q?.answer)}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AboutUs;

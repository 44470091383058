import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Spinner from "../core/Spinner";
import { request } from "../../utils/axios";
import toast from "react-hot-toast";

const MyAccount = () => {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState({
    name: false,
    phone: false,
    address: false,
    image: false,
    password: false,
    dateOfBirth: false,
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    phone: "",
    address: "",
    password: "",
    dateOfBirth: new Date(),
  });

  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    return request({ url: "/myProfile" });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  
  useEffect(() => {
    if (selectedFile) {
      console.log("File selected, updating profile picture");
      setLoading(true);
      updateProfilePicture();
    }
  }, [selectedFile]);

  const { isLoading, data } = useQuery("getUserData", fetchData, {
    onSuccess() {
      console.log(data?.data?.data);
    },
  });

  if (isLoading) {
    return <Spinner />;
  }
  const updateProfilePicture = async () => {
    console.log("Updating profile picture");
    if (!selectedFile) {
      console.log("No file selected for update");
      setLoading(true);

      return;
    }

    const formData = new FormData();
    formData.append("profile_picture", selectedFile);
    console.log("FormData:", formData);

    try {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      await request({
        url: "https://api.tsd-education.com/api/update",
        headers,
        method: "POST",
        data: formData,
      });
      console.log("Image updated successfully");
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (error) {
      // Handle error
      console.error("Error updating profile picture:", error);
    } finally {
      setLoading(false); // Set isLoading to false when the update is complete
    }
  };

  const handleEditClick = (field) => {
    setEditMode((prevEditMode) => ({ ...prevEditMode, [field]: true }));
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [field]: data?.data?.data?.[field] || "",
    }));
  };

  const handleSaveClick = async (field, url, additionalData = {}) => {
    try {
      let valueToUpdate;

      if (field === "image") {
        await updateProfilePicture();
      } else if (field === "address") {
        valueToUpdate = inputValue.address;
      } else {
        valueToUpdate = inputValue[field];
      }

      if (valueToUpdate !== undefined) {
        await request({
          method: "post",
          url: "https://api.tsd-education.com/api/update",
          data: { [field]: valueToUpdate, ...additionalData },
        });

        fetchData();
        setEditMode((prevEditMode) => ({ ...prevEditMode, [field]: false }));
        setInputValue((prevInputValue) => ({ ...prevInputValue, [field]: "" }));
        window.location.reload(false);
        toast.success(t("The change was made successfully"));
      } else {
        console.error(`Invalid value for updating profile ${field}`);
      }
    } catch (error) {
      console.error(`Error updating profile ${field}:`, error);
    }
  };

  const handleCancelClick = (field) => {
    setInputValue((prevInputValue) => ({ ...prevInputValue, [field]: "" }));
    setEditMode((prevEditMode) => ({ ...prevEditMode, [field]: false }));
  };

  const username = data?.data?.data?.Name;
  const formattedUserName = username
    ?.split(" ")
    ?.map((word) => (word ? word[0] : ""))
    ?.join("");

  return (
    <div className="px-4 py-4 md:px-16">
      {/* right side */}
      <div className="mb-3 pb-3 border-b-2">
        <form action="">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="file"
          />
        </form>

        <label htmlFor="file" className="flex items-center gap-x-3 gap-y-2  ">
          {loading ? (
            <span className="spinner"></span>
          ) : data?.data?.data?.profile_picture ===
            "https://api.tsd-education.com/storage" ? (
            <div
              onClick={() =>
                setEditMode((prevEditMode) => ({
                  ...prevEditMode,
                  image: true,
                }))
              }
              className="flex items-center justify-center w-24 h-24 overflow-hidden rounded-lg bg-gray-600 cursor-pointer"
            >
              <div className="flex items-center justify-center w-11 h-11 overflow-hidden rounded-lg bg-gray-600">
                <span className="font-medium text-white">
                  {formattedUserName}
                </span>
              </div>
            </div>
          ) : (
            <img
              src={data?.data?.data?.profile_picture}
              alt={data?.data?.data?.Name}
              className="w-24 h-24 rounded-lg cursor-pointer object-cover"
            />
          )}
          <span
            className="cursor-pointer text-blue-500"
            onClick={() => handleSaveClick("image")}
          >
            <MdOutlineDriveFolderUpload size={28} />
          </span>
        </label>
      </div>

      {/* left side */}
      <div className="grid grid-cols-2 gap-y-8">
        {/* name */}
        <div className="flex flex-col">
          <span className="md:text-lg font-bold">{t("full Name")}</span>
          {editMode.name ? (
            <>
              <input
                type="text"
                value={inputValue.name}
                onChange={(e) =>
                  setInputValue({ ...inputValue, name: e.target.value })
                }
                placeholder={t("new name")}
                className="w-[320px] md:w-[360px] border border-[#c8c7cc] rounded-lg outline-none py-3 px-9 my-2"
              />
              <div className="flex justify-center items-center gap-2 flex-wrap">
                <button
                  onClick={() => handleSaveClick("name")}
                  className="bg-green-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("save")}
                </button>
                <button
                  onClick={() => handleCancelClick("name")}
                  className="bg-red-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("cancel")}
                </button>
              </div>
            </>
          ) : (
            <>
              <span>{username}</span>
              <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleEditClick("name")}
              >
                {t("edit name")}
              </span>
            </>
          )}
        </div>
        {/* phone number */}
        <div className="flex flex-col">
          <span className="md:text-lg font-bold">{t("phone")}</span>
          {editMode.phone ? (
            <>
              <input
                type="text"
                value={inputValue.phone}
                onChange={(e) =>
                  setInputValue({ ...inputValue, phone: e.target.value })
                }
                placeholder={t("new phone number")}
                className="w-[320px] md:w-[360px] border border-[#c8c7cc] rounded-lg outline-none py-3 px-9 my-2"
              />
              <div className="flex justify-center items-center gap-2 flex-wrap">
                <button
                  onClick={() => handleSaveClick("phone")}
                  className="bg-green-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("save")}
                </button>
                <button
                  onClick={() => handleCancelClick("phone")}
                  className="bg-red-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("cancel")}
                </button>
              </div>
            </>
          ) : (
            <>
              <span>{data?.data?.data?.Phone}</span>
              <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleEditClick("phone")}
              >
                {t("edit number")}
              </span>
            </>
          )}
        </div>
        {/* residence */}
        <div className="flex flex-col">
          <span className="md:text-lg font-bold">{t("residence")}</span>
          {editMode.address ? (
            <>
              <input
                type="text"
                value={inputValue.address}
                onChange={(e) =>
                  setInputValue({ ...inputValue, address: e.target.value })
                }
                placeholder={t("new residence")}
                className="w-[320px] md:w-[360px] border border-[#c8c7cc] rounded-lg outline-none py-3 px-9 my-2"
              />
              <div className="flex justify-center items-center gap-2 flex-wrap">
                <button
                  onClick={() => handleSaveClick("address")}
                  className="bg-green-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("save")}
                </button>
                <button
                  onClick={() => handleCancelClick("address")}
                  className="bg-red-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("cancel")}
                </button>
              </div>
            </>
          ) : (
            <>
              <span>{data?.data?.data?.Address}</span>
              <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleEditClick("address")}
              >
                {t("edit house")}
              </span>
            </>
          )}
        </div>
        {/* password */}
        <div className="flex flex-col">
          <span className="md:text-lg font-bold">{t("password")}</span>
          {editMode.password ? (
            <>
              <input
                type="password"
                value={inputValue.password}
                onChange={(e) =>
                  setInputValue({ ...inputValue, password: e.target.value })
                }
                placeholder={t("new password number")}
                className="w-[320px] md:w-[360px] border border-[#c8c7cc] rounded-lg outline-none py-3 px-9 my-2"
              />
              <div className="flex justify-center items-center gap-2 flex-wrap">
                <button
                  onClick={() => handleSaveClick("password")}
                  className="bg-green-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("save")}
                </button>
                <button
                  onClick={() => handleCancelClick("password")}
                  className="bg-red-400 w-24 py-1 px-2 rounded-full text-white"
                >
                  {t("cancel")}
                </button>
              </div>
            </>
          ) : (
            <>
              <span>xxxxxxxxxxxxxxxx</span>
              <span
                className="cursor-pointer text-blue-500"
                onClick={() => handleEditClick("password")}
              >
                {t("edit number")}
              </span>
            </>
          )}
        </div>


        {/* birth date */}
        {/* <div className="flex flex-col">
          <span className="md:text-lg font-bold">{t("birth date")}</span>
 
        </div> */}
      </div>
    </div>
  );
};

export default MyAccount;

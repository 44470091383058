import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Login from "../auth/Login";

export default function ButtonExams() {

    const { t } = useTranslation();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    // ======== التحقق من تسجيل دخول المستخدم ========
    useEffect(() => {
        const tsdUserToken = localStorage.getItem("tsdUserToken");
        setIsUserLoggedIn(!!tsdUserToken);
    }, []);

    const [navigateToLogin, setNavigateToLogin] = useState(false);

    const handleClick = () => {
        setNavigateToLogin(true);
    };

    if (navigateToLogin) {
        return <Login />;
    }

    return (
        <>
            {
                isUserLoggedIn ?
                    (
                        <button className="inline-flex items-center px-20 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg" >
                            <a href="https://presenter.jivrus.com/p/10XlUS7Gd5MON_KjjXo4RXeDj1_YRcaUsluWS1cHwRKY">Go Exam</a>
                        </button>
                    ) : (
                        <button className="bg-red-600 text-white py-2 px-4 rounded-lg "
                            onClick={handleClick}>
                            login, Please
                        </button>
                    )
            }
        </>

    )
}

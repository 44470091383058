import { createContext, useContext, useState } from "react";

const PopupContext = createContext({
  showLogin: false,
  showRegister: false,
  showForget: false,
});

const PopupProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForget, setShowForget] = useState(false);

  // Login functions
  const openLogin = () => {
    setShowLogin(true);
    setShowForget(false);
    setShowRegister(false);
  };

  const closeLogin = () => {
    setShowLogin(false);
  };

  // Register functions
  const openRegister = () => {
    setShowRegister(true);
  };

  const closeRegister = () => {
    setShowRegister(false);
    setShowForget(false);

    setShowLogin(true);
  };

  // Forget Password functions

  const openForget = () => {
    setShowForget(true);
    // setShowLogin(false);
  };

  const closeForget = () => {
    setShowForget(false);
    setShowLogin(true);
  };

  // outside clicker
  const outSideClick = () => {
    setShowLogin(false);
    setShowRegister(false);
    setShowForget(false);
  };
  const value = {
    openLogin,
    closeLogin,
    openRegister,
    closeRegister,
    openForget,
    closeForget,
    showLogin,
    showRegister,
    showForget,
    outSideClick,
  };

  return (
    <PopupContext.Provider value={value}>{children}</PopupContext.Provider>
  );
};

export const usePopupContext = () => useContext(PopupContext);
export default PopupProvider;

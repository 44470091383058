import React from "react";
import { Line } from "../../../assets/DataIndex";
import "./title.css";
import { useTranslation } from "react-i18next";

const Title = ({ text, items, hasAnimate }) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const animate = hasAnimate ? "title__animate" : "";
  return (
    <div className={`flex flex-col my-auto ${items}`}>
      <p
        className={`${animate} text-[#0298b1] font-bold  pb-3 
        ${
          isEnglish
            ? "text-[22px] md:text-[30px] lg:text-[35px]"
            : "text-[25px] md:text-[1.8rem]"
        }
        `}
      >
        {text}
      </p>
      <img src={Line} alt="" className="" />
    </div>
  );
};

export default Title;

import React, { useEffect } from "react";
import "../../App.css";
import DataSummary from "../components/socialInterAction/DataSummary ";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
// import Progress from "../components/home/Progress";
import Hero from "../components/home/Hero";
import { ProgressImage } from "../../assets/DataIndex";
import Features from "../components/home/Features";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "../components/core/trustedSlider/Slider";
import CardHomePage from "../components/home/CardHomePage";
import MapCom from "../components/core/MapCom";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="hero-section md:h-[40vh] lg:h-[80vh] lg:pt-0 mt-[-35px] pt-[20px] md:px-0 ">
        <Hero />
      </div>

      <CardHomePage />


      {/* <Features /> */}

      {/* <div className=" flex justify-center mt-24 px-2 -mb-20">
        <img data-aos="zoom-in" src={ProgressImage} alt="" className="" />
      </div> */}



      {/* <Slider /> */}

      <DataSummary />

      <MapCom />

      <div className=" mt-32">
      <UserComment />
      </div>
      <SendEvaluation />


    </>
  );
};

export default Home;

import React from "react";
// import InstructorCard from "../components/ourExperts/InstructorCard";
import ImageHero from "../components/core/ImageHero";
import { useTranslation } from "react-i18next";
import ContactCard from "../components/contactUs/ContactCard";
import EmailContact from "../components/contactUs/EmailContact";
import DataSummary from "../components/socialInterAction/DataSummary ";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import { request } from "../utils/axios";
import { useQuery } from "react-query";

const ContactUs = () => {
  const { i18n } = useTranslation();

  const fetchHeroImage = () => {
    const url = "/themes/contact us";
    return request({ url });
  };
  const { data: heroImage } = useQuery(
    "getContactUsHeroImage",
    fetchHeroImage,
    {
      onSuccess() {
        // console.log(data?.data?.data);
      },
      onError(e) {
        console.log(e.message);
      },
    }
  );
  // const fetchData = () => {
  //   const url = "/Contact/information";
  //   return request({ url });
  // };
  // const { data } = useQuery("getContactData", fetchData, {
  //   onSuccess() {
  //     // console.log(data?.data?.data);
  //   },
  //   onError(e) {
  //     console.log(e.message);
  //   },
  // });
  const { t } = useTranslation();
  return (
    <>
      <div className="px-8 md:px-16">
        <ImageHero
          img={heroImage?.data?.data[0]?.image}
          title={t("contact us")}
          desc={t("expert desc")}
        />
        <div className="sm:px-16">
          <div className="flex  justify-center items-center gap-6 flex-wrap my-12">
            <ContactCard />
          </div>
          <EmailContact />
        </div>
      </div>
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110502.61185077461!2d31.340866256910296!3d30.05961134304527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583fa60b21beeb%3A0x79dfb296e8423bba!2z2KfZhNmC2KfZh9ix2KnYjCDZhdit2KfZgdi42Kkg2KfZhNmC2KfZh9ix2KnigKw!5e0!3m2!1sar!2seg!4v1706467785262!5m2!1sar!2seg"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="w-screen h-[300px] my-12"
      ></iframe> */}

      {/* <DataSummary /> */}
      {/* <SendEvaluation /> */}
      <div className="flex flex-wrap justify-center items-center gap-x-4  px-8 lg:px-16 mt-12">
        {/* <UserComment /> */}
      </div>
    </>
  );
};

export default ContactUs;

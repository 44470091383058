import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { request } from "../../utils/axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Spinner from "../core/Spinner";
const DropCourseDetails = ({ courseDetails }) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const { id } = useParams();

  const handleToggle = () => {
    setOpen(!open);
  };
  // ================== fetch data ==================
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";
  const fetchData = () => {
    const url = `/content/${id}?lang=${i18n.language || lang}`;
    return request({ url });
  };
  const { isLoading, data } = useQuery("getCourseContent", fetchData, {
    onSuccess() {
      // console.log(data?.data?.data?.name_of_chapter);
    },
  });
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div relative cursor-pointer>
      <div
        onClick={handleToggle}
        className={`flex justify-between items-center px-6 py-2 font-bold my-2 ${
          open
            ? "bg-[#9E72FB] text-white rounded-t-lg"
            : "text-[#9E72FB] bg-[#F5F4F4]"
        }`}
      >
        {data?.data?.data?.name_of_chapter}
        {open ? <FaAngleUp size={23} /> : <FaAngleDown size={23} />}
      </div>

      <div className={` w-full  bg-white p-6 ${open ? "block" : "hidden"}`}>
        <p
          className="md:w-[80%]"
          style={{
            color: "rgba(0, 0, 0, 0.50)",
          }}
        >
          {data?.data?.data?.content_of_chapter}
        </p>
      </div>
    </div>
  );
};

export default DropCourseDetails;

import React, { useEffect } from "react";
import { IoMdArrowRoundForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { usePopupContext } from "../../context/PopupProvider";
import AOS from "aos";
import "aos/dist/aos.css";
const FormModel = ({ backFun, children }) => {
  const { i18n } = useTranslation();
  const { outSideClick } = usePopupContext();
  const isEnglish = i18n.language === "en";

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="z-[1000] fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div
          data-aos="zoom-in"
          className="w-[320px] sm:w-[400px] z-[1010] relative py-4 px-3 rounded-lg bg-white"
        >
          <IoMdArrowRoundForward
            size={25}
            className={`cursor-pointer ${isEnglish ? "rotate-180" : ""}`}
            onClick={backFun}
          />
          <div className="content">{children}</div>
        </div>
        
        <div
          className="z-[1001] fixed top-0 left-0 w-full h-full"
          onClick={outSideClick}
        />
      </div>
    </>
  );
};

export default FormModel;

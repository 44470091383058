import React from "react";
import { useQuery } from "react-query";
import { request } from "../../utils/axios";
import Spinner from "../core/Spinner";
import { FaBook } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const MyCourses = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  // ================== Fetch data ===================
  const navigate = useNavigate();

  const fetchData = () => {
    return request({ url: "/Profile/MyCourses" });
  };

  const { isLoading, data } = useQuery("getUserCourses", fetchData, {
    onSuccess() {
      // console.log(data?.data?.data);
    },
  });
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className=" px-4 py-4 md:px-16 flex items-center flex-wrap gap-x-3 gap-y-3">
      {data?.data?.data?.map((course) => {
        return (
          <div
            key={course?.id}
            className="w-[260px] h-[400px]   rounded-lg relative"
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            onClick={() => navigate(`/course-details/${course?.id}`)}
          >
            <span
              className={`bg-[#9E72FB] text-white rounded-2xl py-1 px-2 text-sm absolute top-[2%] ${
                isArabic ? "right-[5%]" : "left-[5%]"
              } `}
              style={{ background: "rgba(158,114,251,.8)" }}
            >
              {course?.leve}
            </span>
            <img
              src={course.image}
              alt=""
              className="w-full rounded-t-lg h-[180px]"
            />
            <div className={`px-4 pb-4 relative `}>
              <div className="pt-4">
                <div className="pb-2">
                  <span className="text-black opacity-60 font-semibold">
                    {course?.name}
                  </span>

                  <p className="text-black opacity-60 font-bold  ellipsis ">
                    {course?.what_is_expected_}
                  </p>
                </div>

                <div className="flex flex-col items-center gap-y-2 mt-2">
                  <div className="flex justify-between gap-x-16 text-[#31225B] font-semibold py-4">
                    <span className="flex items-center font-semibold gap-x-2  ">
                      <FaBook />
                      {course?.number_of_chapters}
                    </span>
                    <span className="flex items-center gap-x-2 font-semibold">
                      <IoStar className="text-yellow-300" />
                    </span>
                  </div>
                  <button className="bg-[#31225B] text-white rounded-md py-2 px-6 text-[14px]">
                    تفاصيل الكورس
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MyCourses;

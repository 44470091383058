import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { findInputError, isFormInvalid } from "../../utils/auth";
import { useFormContext } from "react-hook-form";
import { MdError } from "react-icons/md";

const AuthInput = ({
  Icon,
  id,
  type,
  placeholder,
  validation,
  name,
  value,
  onChange,
}) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    // Set default values when the component mounts
    setValue(name, value);
  }, [setValue, name, value]);
  const inputError = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);
  return (
    <>
      <div className="relative">
        <input
          id={id}
          placeholder={t(placeholder)}
          type={type}
          className="w-[290px] md:w-[360px] border border-[#c8c7cc] rounded-lg outline-none py-3 px-9 my-2"
          onChange={onChange}
          {...register(`${name}`, validation)}
        />
        <Icon
          className={`absolute top-1/2 ${
            isArabic ? "right-0" : "left-5"
          } transform translate-x-[-50%] translate-y-[-50%] text-2xl text-[#5A6067]`}
        />
      </div>
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputError.error.message}
            key={inputError.error.message}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p
      className="w-fit flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};

export default AuthInput;

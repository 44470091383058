import React from "react";
import Title from "./Title";

export default function MapCom() {
  return (
    <div>
      <div className="w-full h-[50vh] mt-5">
     
      <div className="w-[80%] text-center mx-auto my-4">
            <Title
              text={"our places"}
              items="items-center"
            />
          </div>

          <iframe
            className="w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3418.1190569675528!2d31.397393999999995!3d31.05078499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDAzJzAyLjgiTiAzMcKwMjMnNTAuNiJF!5e0!3m2!1sar!2seg!4v1724326156099!5m2!1sar!2seg"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
      
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import ImageHero from "../components/core/ImageHero";

import Title from "../components/core/Title";
import StudentCard from "../components/student/StudentCard";
import Pagination from "../components/core/Pagination";
import Certificate from "../components/courses/Certificate";
import DataSummary from "../components/socialInterAction/DataSummary ";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import { request } from "../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../components/core/Spinner";

const StudentBusiness = () => {
  const { t, i18n } = useTranslation();
  const [pageNumber, setPageNumber] = useState(0);

  // ==================== FETCH DATA =================
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/Works/Student?lang=${i18n.language || lang}`;
    return request({ url });
  };
  const { data, isLoading } = useQuery("getOurExperts", fetchData, {
    onSuccess() {
      // console.log(data?.data?.data);
    },
    onError(e) {
      console.log(e.message);
    },
  });
  // useEffect(() => {
  //   refetch();
  // }, []);
  const fetchHeroImage = () => {
    const url = "/themes/students";
    return request({ url });
  };
  const { data: heroImage } = useQuery("getStudentHeroImage", fetchHeroImage, {
    onSuccess() {
      // console.log(data?.dat?.data);
    },
    onError(e) {
      console.log(e.message);
    },
  });
  if (isLoading) {
    return <Spinner />;
  }
  // ==================== Pagination =================

  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = data?.data?.data.length / usersPerPage;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div className="px-8 md:px-16">
      <ImageHero
        img={heroImage?.data?.data[0]?.image}
        title={t("student business")}
        desc={t("expert desc")}
      />
      <Title text={t("student business")} items="items-center" />
      <motion.div
        initial={{ opacity: 0, x: 400 }}
        animate={{ opacity: 1, x: 0 }}
      >
        <div className=" flex justify-center items-center flex-wrap gap-4 my-8 xl:w-[90%] mx-auto ">
          {Array.isArray(data?.data?.data) &&
            data?.data?.data
              .slice(pagesVisited, pagesVisited + usersPerPage)
              .map((item) => (
                <StudentCard
                  key={item?.id}
                  data={item}
                  trainerName={item?.trainer?.name}
                  courseName={item?.course?.name}
                  courseId={item?.course?.id}
                />
              ))}
          <div className="flex flex-col justify-between p-5 w-full h-full">
            <Pagination
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              breakLabel={"..."}
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              marginPagesDisplayed={0}
              nextClassName={"item next "}
              nextLabel={">"}
              onPageChange={changePage}
              pageCount={pageCount}
              pageClassName={"item pagination-page "}
              pageRangeDisplayed={2}
              previousClassName={"item previous"}
              previousLabel={"<"}
            />
          </div>
        </div>
      </motion.div>
      <Certificate />
      <DataSummary />
      <SendEvaluation />
      <div className="flex flex-wrap justify-center items-center gap-x-4  px-8 lg:px-16 mt-12">
        <UserComment />
      </div>
    </div>
  );
};

export default StudentBusiness;

import React from "react";
import { request } from "../../utils/axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Spinner from "../core/Spinner";

const MyCertification = () => {
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/MyCertificate?lang=${i18n.language || lang}`;
    return request({ url });
  };
  const { data, isLoading } = useQuery("getOurExperts", fetchData, {
    onSuccess() {
      // console.log(data?.data?.data);
    },
    onError(e) {
      console.log(e.message);
    },
  });
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex items-center gap-x-3 flex-wrap px-4 py-4 md:px-16">
      {data?.data?.data?.map((certificate) => {
        return (
          <div className="">
            <img
              src={certificate?.image}
              alt=""
              className="w-[300px] h-[230px]"
            />

            <p className="bg-white p-2 text-[#9e72fb] font-bold">
              {certificate?.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default MyCertification;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeftLong, FaArrowRight } from "react-icons/fa6";
import CourseSubscripe from "../../view/subscription/CourseSubscripe";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../core/Spinner";

const InstructorCard = ({ courseId, instructorData }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { id } = useParams();

  //======== handle open and close subscribe form ========
  const openForm = () => {
    setOpen(true);
  };

  const closeForm = () => {
    setOpen(false);
  };

  //======== handle sliding between instructors ========
  const nextInstructor = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % instructorData.length);
  };

  const prevInstructor = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + instructorData.length) % instructorData.length
    );
  };

  const currentInstructor =
    instructorData && instructorData.length > 0
      ? instructorData[currentIndex]
      : null;

  // ======== handle cases of course subscribe ========
  const tsdUserToken = localStorage.getItem("tsdUserToken");
  const isUserLoggedIn = !!tsdUserToken;

  const fetchData = () => {
    return request({ url: "/Profile/MyCourses" });
  };

  const { isLoading, data } = useQuery("getUserCourses", fetchData, {
    onSuccess(data) {
      if (data && data.data && data.data.data) {
        // Add null checks
        console.log(data.data.data);
        const courseIds = data.data.data.map((course) => course.id);
        const isCourseSubscribed = courseIds.includes(courseId);

        setIsSubscribed(isCourseSubscribed);
      }
    },
  });
  // const subscribedCourses = data?.data?.data || [];
  // const isCourseSubscribed = subscribedCourses.some(
  //   (course) => course.id === courseId
  // );

  useEffect(() => {
    if (isUserLoggedIn) {
      fetchData();
    }
  }, [isUserLoggedIn, courseId]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      {currentInstructor && (
        <div className="bg-[#00529b] flex flex-col items-center justify-around h-[450px] py-8 px-4 rounded-lg mt-4 lg:mt-0">
          <img
            src={currentInstructor.image}
            alt=""
            className="w-32 h-32 rounded-full"
          />
          <span className="font-bold text-xl text-white">{currentInstructor.name}</span>
          <p className="text-white opacity-50 text-base md:text-sm ">
            {currentInstructor.speciality}
          </p>
          {/* navigate(`/expert-details/${id}`); */}
          <Link
            to={`/expert-details/${id}`}
            className="text-white flex items-center"
          >
            {t("read more")}....{" "}
            {isArabic ? (
              <FaArrowLeftLong className="mt-2" />
            ) : (
              <FaArrowRight className="mt-2" />
            )}
          </Link>
          {isUserLoggedIn ? (
            <>
              {isSubscribed ? (
                <p className="font-bold text-lg text-white border-b-2 border-white pb-1">
                  {t("You are already subscribed to this course.")}
                </p>
              ) : (
                <button
                  onClick={openForm}
                  className="bg-[#2180a6] text-white py-1 px-4 min-w-[120px] my-1 rounded-2xl text-sm xl:text-base"
                >
                  {t("subscribe in course")}
                </button>
              )}
            </>
          ) : (
            <p className="font-bold text-lg text-white border-b-2 border-white pb-1">
              {t("You are not logged in yet.")}
            </p>
          )}

          {open && isUserLoggedIn && (
            <CourseSubscripe handleCloseForm={closeForm} courseId={courseId} />
          )}

          {instructorData.length > 1 && (
            <div className="flex items-center gap-x-4 mt-4">
              <button
                onClick={prevInstructor}
                className="py-[2px] px-2 rounded text-xl bg-[#9E72FB] text-white flex justify-center items-center "
              >
                &lt;
              </button>
              <button
                onClick={nextInstructor}
                className="py-[2px] px-2 rounded text-xl bg-[#9E72FB] text-white flex justify-center items-center "
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InstructorCard;

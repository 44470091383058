import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
// import { CiLock } from "react-icons/ci";
import FormButton from "../../components/core/FormBtn";
import { FormProvider, useForm } from "react-hook-form";
import FormModel from "../../components/auth/FormModel";
import AuthInput from "../../components/auth/AuthInput";
import { email_validation } from "../../utils/auth/inputValidations";
import { usePopupContext } from "../../context/PopupProvider";

import Register from "./Register";

const ForgetPass = () => {
  const { t } = useTranslation();
  const registerRef = useRef();
  const { openRegister, closeForget, showRegister } = usePopupContext();

  useEffect(() => {
    registerRef.current && registerRef.current.focus();
  }, []);

  const methods = useForm();
  const onSubmit = methods.handleSubmit((data) => {
    console.log(data);
    methods.reset();
  });
  return (
    <FormProvider {...methods}>
      <FormModel backFun={closeForget}>
        <div className="text-center flex flex-col items-center justify-between">
          <h2 className="text-3xl font-semibold mt-6 mb-1">
            {t("forget pass")}
          </h2>
          <span className="text-lg mb-5">{t("forget password desc")}</span>
        </div>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="container"
        >
          <div className=" flex justify-center  ">
            <div>
              <AuthInput {...email_validation} Icon={MdOutlineEmail} />
            </div>
          </div>

          <FormButton text={t("send")} submit={onSubmit} />
        </form>
        <div>
          <p className="text-center">
            {t("create account")}
            <button
              className="text-[#726DF1] cursor-pointer underline"
              onClick={openRegister}
            >
              {t("new account")}
            </button>
            {showRegister && <Register />}
          </p>
        </div>
      </FormModel>
    </FormProvider>
  );
};

export default ForgetPass;

import axios from "axios";
import { createContext, useContext } from "react";

const ContactContext = createContext({
  submitOpinion: async () => {
    throw new Error("submitOpinion function not provided");
  },
});

const ContactProvider = ({ children }) => {
  const submitOpinion = async (phone, message) => {
    try {
      const response = await axios.post(
        "https://api.tsd-education.com/api/contact_us",
        {
          phone: phone,
          message: message,
        }
      );

      console.log("message submitted successfully:", phone, message);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.error("Validation errors:", error.response.data);
      } else {
        console.error("Error submitting message:", error);
      }
      throw error;
    }
  };
  const value = { submitOpinion };

  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  );
};
export default ContactProvider;
export const useContactContext = () => useContext(ContactContext);

import React from "react";
import Title from "../core/Title";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const AboutInstructor = ({ item }) => {
  const { t } = useTranslation();


  return (
    <div className="my-6">
      <Title text={t("about instructor")} items="items-center" />
      <div className="mt-4">
        <span className="text-instractor font-bold text-xl border-b-4 pb-1">
          {item?.name}
        </span>
      </div>
      <ul>
        <motion.div
          initial={{ opacity: 0, x: 400 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <li className="md:w-[80%] mt-4 mx-8 list-disc">{item?.speciality}</li>
          <li className="md:w-[80%] mt-4 mx-8 list-disc">
            {item?.description}
          </li>
        </motion.div>
      </ul>
    </div>
  );
};

export default AboutInstructor;

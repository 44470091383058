import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InstructorCard from "../components/ourExperts/InstructorCard";
import Title from "../components/core/Title";
import AboutInstructor from "../components/ourExperts/AboutInstructor";
import { request } from "../utils/axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Spinner from "../components/core/Spinner";
const ExpertDetails = () => {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState("about instructor");
  const [showDesc, setShowDesc] = useState(false);

  const { id } = useParams();

  const handleShowDesc = (item) => {
    setActive(item);
    setShowDesc(item === "about instructor");
  };

  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/about/trainers/${id}`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery(
    "getCourseCategory",
    fetchData,
    {
      onSuccess() {
        // console.logconso(data?.data?.data);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [i18n.language]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="  px-4 md:w-[60%] mx-auto">
        {/* <ReactPlayer
          url={Anime}
          width="100%"
          height="100%"
          controls={true}
          playing={false}
          loop={false}
          volume={0.8}
          muted={false}
          playbackRate={1}
          style={{ borderRadius: "20px" }}
        /> */}

        <iframe
          src={data?.data?.data?.trainer?.demo}
          title="description"
          className="w-full h-[400px] rounded-xl"
        ></iframe>

        <div className=" flex justify-between items-center gap-x-3 mt-12">
          {["about instructor", "special courses"].map((item, index) => (
            <span
              key={index}
              className={`md:w-full w-60 rounded-lg text-center cursor-pointer py-2 ${
                active !== item
                  ? " bg-[#00529b] text-white"
                  : " bg-[#F5F4F4] font-bold"
              }`}
              onClick={() => {
                handleShowDesc(item);
              }}
            >
              {t(item)}
            </span>
          ))}
        </div>
      </div>

      <div className=" my-12 px-8 lg:px-24 xl:px-32  ">
        {active === "about instructor" ? (
          <>
            <Title text={t("special courses")} items={"items-start"} />
            <div className=" flex flex-col items-center mt-4 sm:grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-y-4 place-items-center">
              
                <InstructorCard item={data?.data?.data?.trainer?.Courses} />
           
            </div>
          </>
        ) : (
          <AboutInstructor
            key={data?.data?.data?.trainer?.id}
            item={data?.data?.data?.trainer}
          />
        )}
      </div>
    </div>
  );
};

export default ExpertDetails;

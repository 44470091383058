import ImgIcon from "../../../assets/hero-section/whatsapp-symbol-logo-svgrepo-com.svg";

export default function WhatsAppIcon() {
  return (
    <div className="fixed right-4 bottom-0 p-4 mb-20 ml-4 z-50 flex items-center">
      <a
        href="https://wa.me/+201029126073"
        className="flex items-center relative"
      >
        <img src={ImgIcon} className="w-[50px]" />
      </a>
      <div className="w-[100px] text-center absolute bg-white animate-slideIn ml-[-20px] rounded-md mb-28">
        <a href="https://wa.me/+201029126073">
          <p className="w-full px-[10px] py-1 text-center text-[14px]">
            تواصل معنا
          </p>
        </a>
      </div>
      {/* <div>
          <span className="w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-black transform rotate-[180deg]"></span>
        </div> */}
    </div>
  );
}

import { useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { AiOutlineRollback } from "react-icons/ai";
import { BiHomeSmile, BiUser } from "react-icons/bi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { FiSettings, FiShoppingCart } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Logo } from "../../../assets/DataIndex";
import LogoImg from "../../../assets/top-header/Logo.svg";

export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();
  useClickAway(ref, () => setOpen(false));
  const toggleSidebar = () => setOpen((prev) => !prev);
  return (
    <>
      <button
        onClick={toggleSidebar}
        className="p-3 border-2 border-[#2180A6] rounded-xl"
        aria-label="toggle sidebar"
      >
        <GiHamburgerMenu className="text-[#2976A6] border-[#9E72FB]" />
      </button>
      <AnimatePresence mode="wait" initial={false}>
        {open && (
          <>
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
            ></motion.div>
            <motion.div
              {...framerSidebarPanel}
              className="fixed top-0 bottom-0 left-0 z-50 w-full h-screen max-w-xs border-r-2  bg-[#2976A6]"
              ref={ref}
              aria-label="Sidebar"
            >
              <div className="flex items-center justify-between p-5 border-b-2 border-white bg-white">
                <div className="w-15 h-10 mb-4">
                  <img src={LogoImg} />
                </div>
                <button
                  onClick={toggleSidebar}
                  className="p-3 border-2 border-[#2180A6] text-[#2976A6] rounded-xl"
                  aria-label="close sidebar"
                >
                  <AiOutlineRollback size={20} />
                </button>
              </div>

              <ul>
                {navLinks.map((item, idx) => {
                  const { name, route } = item;
                  return (
                    <li key={name}>
                      <a
                        onClick={toggleSidebar}
                        href={route}
                        className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-[#00529b] border-white hover:font-bold text-white"
                      >
                        <motion.span {...framerText(idx)}>
                          {t(name)}
                        </motion.span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const navLinks = [
  { name: "home", Icon: BiHomeSmile, route: "/" },
  {
    name: "exams",
    route: "/exams",
  },
  { name: "courses", Icon: BiUser, route: "/courses" },
  { name: "experience", Icon: FiSettings, route: "/our-expert" },
  { name: "us", Icon: FiShoppingCart, route: "about-us" },
  {
    name: "contact",
    Icon: HiOutlineChatBubbleBottomCenterText,
    route: "/contact-us",
  },
  // { name: "student business", route: "/student-business" },
];

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.2 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: {
      delay: 0.5 + delay / 10,
    },
  };
};

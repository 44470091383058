import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const apiUrl = "https://api.tsd-education.com/api";
// https://api.tsd-education.com/api

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ name, email, phone, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${apiUrl}/register`,
        { name, email, phone, password },
        config
      );
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${apiUrl}/login`,
        { email, password },
        config
      );
      console.log("data returned from login", data);

      // store user's token in local storage
      localStorage.setItem("tsdUserToken", JSON.stringify(data.data.token));
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem("tsdUserToken");
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// subscription function
export const subscribeToCourse = createAsyncThunk(
  "subscription/subscribe",
  async ({ city_id, college_id, age, courseId }, { rejectWithValue }) => {
    try {
      const tsdUserToken = localStorage.getItem("tsdUserToken");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tsdUserToken.replace(/['"]+/g, "")}`,
        },
      };

      const url = `${apiUrl}/subscribe/${courseId}`;

      console.log("Request Config: ", config);
      const response = await axios.post(
        url,
        { city_id, college_id, age, courseId },
        config
      );

      if (response.data && response.data.status === "حدث خطأ ما !") {
        // Consider this as an error
        return rejectWithValue(response.data);
      }

      return { courseId, ...response.data };
    } catch (error) {
      console.error("Error Response: ", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.status === "حدث خطأ ما !"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

import React from "react";
import { useTranslation } from "react-i18next";
import MainBtn from "../../core/MainBtn";
import { useCommentContext } from "../../../context/CommentProvider";
import toast from "react-hot-toast";

const SendEvaluation = () => {
  const { t } = useTranslation();
  const { submitOpinion } = useCommentContext();

  const handleFormSubmit = async (event) => {
    // event.preventDefault();

    const name = event.target.elements.name.value;
    const opinion = event.target.elements.comment.value;

    try {
      await submitOpinion(name, opinion);
      event.target.reset();
      toast.success(t("success send"));
    } catch (error) {
      console.error("Error submitting opinion:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-y-12 mt-4 -mb-16">
   

      <form onSubmit={handleFormSubmit}>
        <div className="flex flex-col items-center gap-y-4">
          <input
            type="text"
            className="  w-[320px] md:w-[450px] h-8 px-2 placeholder:text-[12x] outline-none"
            placeholder={t("full Name")}
            name="name"
            required
          />
          <textarea
            name="comment"
            id="comment"
            
            className="  w-[320px] md:w-[450px] h-[200px] p-2 placeholder:text-[12x] outline-none"
            placeholder={`${t("write comment")}...`}
            required
          ></textarea>
          <MainBtn type="submit" text={t("Send")} />
        </div>
      </form>
    </div>
  );
};

export default SendEvaluation;

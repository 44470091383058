import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IoPersonOutline } from "react-icons/io5";

import { MdPhone } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import FormButton from "../../components/core/FormBtn";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormModel from "../../components/auth/FormModel";
import AuthInput from "../../components/auth/AuthInput";
import {
  email_validation,
  name_validation,
  num_validation,
  password_validation,
} from "../../utils/auth/inputValidations";

import { usePopupContext } from "../../context/PopupProvider";


import { registerUser } from "../../redux/auth/authActions";

const Register = () => {
  const { t } = useTranslation();
  const registerRef = useRef();
  const { closeRegister, openLogin } = usePopupContext();
  useEffect(() => {
    registerRef.current && registerRef.current.focus();
  }, []);

  //=======register function =============
  const { success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const methods = useForm();

  const onSubmit = methods.handleSubmit((data) => {
    methods.reset();
    data.email = data.email.toLowerCase();
    dispatch(registerUser(data));
    closeRegister();
    openLogin();
  });

  return (
    <FormProvider {...methods}>
      <FormModel backFun={closeRegister}>
        <div className="text-center flex flex-col items-center justify-between">
          <h2 className="text-3xl font-semibold mt-6 mb-1">{t("hello")}</h2>
          <span className="text-lg mb-5">{t("regiser")}</span>
        </div>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="container"
        >
          {/* {error && <p className="text-red-500 text-center">{error}</p>} */}
          {success && (
            <p className="text-green-500 text-center">
              {t("successfully registered")}
            </p>
          )}
          <div className=" flex justify-center  ">
            <div>
              <AuthInput {...name_validation} Icon={IoPersonOutline} />
              <AuthInput {...email_validation} Icon={MdOutlineEmail} />
              <AuthInput {...num_validation} Icon={MdPhone} />
              <AuthInput {...password_validation} Icon={CiLock} />
            </div>
          </div>

          <FormButton text={t("regiser")} submit={onSubmit} />
        </form>
        <div>
          <p className="text-center">
            {t("have account")}
            <span
              className="text-[#726DF1] cursor-pointer px-1 underline"
              onClick={() => closeRegister()}
            >
              {t("login")}
            </span>
          </p>
        </div>
      </FormModel>
    </FormProvider>
  );
};

export default Register;

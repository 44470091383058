import { useTranslation } from "react-i18next";
import Title from "../../components/core/Title";

import Img from "../../../assets/Exams/WhatsApp Image 2024-08-27 at 2.35.07 PM.jpeg";
import BannarExams from "./BannarExams";

import ButtonExams from "./ButtonExams";

export default function Exams() {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-screen h-full  ">
      <BannarExams />

      <div className="w-[90%]  h-full mx-auto pt-4">
        <Title text={t("ourexams")} items="items-center" />

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-center mx-auto w-[100%] md:w-[90%]">
          <div className="max-w-sm bg-white border-gray-200  shadow mt-10 rounded-md dark:bg-gray-900">
            <img src={Img} alt="" className="rounded-md h-[250px] w-full" />

            <div className="p-5 text-center">
              
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-black dark:text-white">
                  English Placement Test
                </h5>
            
              <p className="mb-3 font-normal text-gray-400">
                The goal of the test is to determine your level of English
                language in order to improve you skills in English.
              </p>
              <ButtonExams />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Home from "./actions/view/Home";
import Root from "./actions/components/root/Root";
import AboutUs from "./actions/view/AboutUs";
import Courses from "./actions/view/Courses";
import CourseDetails from "./actions/view/CourseDetails";
import StudentBusiness from "./actions/view/StudentBusiness";
import StdBusinessDetails from "./actions/view/StdBusinessDetails";
import OurExperts from "./actions/view/OurExperts";
import ExpertDetails from "./actions/view/ExpertDetails";
import ContactUs from "./actions/view/ContactUs";
import Spinner from "./actions/components/core/Spinner";

import Profile from "./actions/view/Profile";
import Exams from "./actions/view/Examspage/Exams";
import Login from "./actions/view/auth/Login";




function App() {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1400);
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
    if (i18n.language === "ar") {
      document.getElementsByTagName("body")[0].style.direction = "rtl";
    } else {
      document.getElementsByTagName("body")[0].style.direction = "ltr";
    }
  }, [i18n.language]);



  return (
    <>
      {loading ? (
        <Spinner />
      ) : (

        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="/exams" element={<Exams />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/courses" element={<Courses />} />

            <Route path="/course-details/:id" element={<CourseDetails />} />
            <Route path="student-business" element={<StudentBusiness />} />
            <Route
              path="student-business-details/:id"
              element={<StdBusinessDetails />}
            />
            <Route path="/our-expert" element={<OurExperts />} />
            <Route path="expert-details/:id" element={<ExpertDetails />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="/my-profile" element={<Profile />} />

         
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;

// import { useTranslationHelper } from "./TranslationUtility";

// const t = useTranslationHelper();
export const name_validation = {
  name: "name",
  label: "name",
  type: "text",
  id: "name",
  placeholder: "full Name",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 30,
      message: "30 characters max",
    },
  },
};

export const desc_validation = {
  name: "description",
  label: "description",
  multiline: true,
  id: "description",
  placeholder: "write description ...",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    maxLength: {
      value: 200,
      message: "200 characters max",
    },
  },
};

export const password_validation = {
  name: "password",
  label: "password",
  type: "password",
  id: "password",
  placeholder: "password",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    minLength: {
      value: 6,
      message: "min 6 characters",
    },
  },
};

export const num_validation = {
  name: "phone",
  label: "number",
  type: "number",
  id: "num",
  placeholder: "phone",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value: /^(010|011|012|015)\d{8,11}$/,
      message: "not Egyptian Phone Number",
    },
  },
};

export const email_validation = {
  name: "email",
  label: "email address",
  type: "email",
  id: "email",
  placeholder: "email",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "not valid",
    },
  },
};

// subscription
export const age_validation = {
  name: "age",
  label: "Age",
  type: "number",
  id: "age",
  placeholder: "Age",
  validation: {
    required: {
      value: true,
      message: "required",
    },
    min: {
      value: 7,
      message: "Minimum rating is 2",
    },
    max: {
      value: 99,
      message: "Maximum rating is 2",
    },
  },
};
// comment inputs

// sendEvaluationValidations.js

export const comment_validation = {
  name: "comment",
  label: "comment",
  multiline: true,
  id: "comment",
  placeholder: "Write your comment...",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    maxLength: {
      value: 50,
      message: "50 characters max",
    },
  },
};

export const rating_validation = {
  name: "rating",
  label: "rating",
  type: "number",
  id: "rating",
  placeholder: "Enter your rating...",
  validation: {
    required: {
      value: true,
      message: "Required",
    },
    min: {
      value: 3,
      message: "Minimum rating is 1",
    },
    max: {
      value: 20,
      message: "Maximum rating is 5",
    },
  },
};

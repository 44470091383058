import React from "react";

import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const SocialIcons = () => {

  //  =================== fetchData ===================
  const fetchData = () => {
    return request({ url: "/SocialMedia" });
  };
  const { isLoading, data } = useQuery("getSocialLinks", fetchData, {
    onSuccess: () => {
      // console.log(data?.data?.data);
    },
  });
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex items-center gap-x-1">
      {data?.data?.data.map((icon) => {
        return (
          <Link to={icon?.Link} key={icon?.id}>
            <img
              src={icon?.image}
              alt="image_"
              className="w-8 h-8 rounded-full cursor-pointer"
            />
          </Link>
        );
      })}
    </div>
  );
};

export default SocialIcons;

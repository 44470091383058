import React, { useState, useEffect } from "react";
import ImageHero from "../components/core/ImageHero";
import { OurExportsImage } from "../../assets/DataIndex";
import { useTranslation } from "react-i18next";
import Title from "../components/core/Title";
import ExpertCard from "../components/ourExperts/ExpertCard";
import Pagination from "../components/core/Pagination";
import DataSummary from "../components/socialInterAction/DataSummary ";
import { request } from "../utils/axios";
import Spinner from "../components/core/Spinner";

const OurExperts = () => {
  const { t, i18n } = useTranslation();
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // ==================== FETCH DATA =================
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const lang = localStorage.getItem("lang")
          ? JSON.parse(localStorage.getItem("lang"))
          : "ar";
        const url = `/trainers?lang=${lang.language || lang}`;
        const response = await request({ url });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [i18n.language]);

  if (isLoading) {
    return <Spinner />;
  }

  // =============== pagination =============

  const usersPerPage = 12;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = data?.data?.length / usersPerPage;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="px-2 md:px-16">
      <ImageHero
        img={OurExportsImage}
        title={t("our exports")}
        desc={t("expert desc")}
      />

      <Title text={t("experience")} items="items-center" />

      <div className=" my-12 grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 place-items-center  gap-x-2 gap-y-20 ">
        {data?.data
          .slice(pagesVisited, pagesVisited + usersPerPage)
          .map((item) => (
            <ExpertCard item={item} key={item?.id} />
          ))}
      </div>
      <div className="p-5 h-full container mx-auto">
        <Pagination
          activeClassName={"item active "}
          breakClassName={"item break-me "}
          breakLabel={"..."}
          containerClassName={"pagination"}
          disabledClassName={"disabled-page"}
          marginPagesDisplayed={0}
          nextClassName={"item next "}
          nextLabel={">"}
          onPageChange={changePage}
          pageCount={pageCount}
          pageClassName={"item pagination-page "}
          pageRangeDisplayed={2}
          previousClassName={"item previous"}
          previousLabel={"<"}
        />
      </div>
      <DataSummary />
    </div>
  );
};

export default OurExperts;

import React from "react";
import Logo from "../../../assets/top-header/Logo.svg";

const Spinner = () => {
  return (
    <div className="loading-page w-screen h-screen fixed top-0 left-0 z-50 bg-white flex items-center justify-center">
      <div className="flex flex-col items-center gap-3 mt-[-100px] sm:mt-0">
        <img alt="logo" src={Logo} className="w-[130px]" />
        <div className="loader "></div>
      </div>
    </div>
  );
};

export default Spinner;

import axios from "axios";
import { createContext, useContext } from "react";

const CommentContext = createContext({
  submitOpinion: async () => {
    throw new Error("submitOpinion function not provided");
  },
});

const CommentProvider = ({ children }) => {
  const submitOpinion = async (name, opinion) => {
    try {
      const response = await axios.post(
        "https://api.tsd-education.com/api/Opinion/create",
        {
          name: name,
          opinion: opinion,
        }
      );

      console.log("Opinion submitted successfully:", name, opinion);
      return response.data;
    } catch (error) {
      console.error("Error submitting opinion:", error);
      throw error;
    }
  };

  const value = { submitOpinion };
  return (
    <CommentContext.Provider value={value}>{children}</CommentContext.Provider>
  );
};

export default CommentProvider;

export const useCommentContext = () => useContext(CommentContext);

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Aos from "aos";

import Icon1 from "../../../assets/hero-section/certificate-svgrepo-com.svg";
import Icon2 from "../../../assets/hero-section/students-student-svgrepo-com 1.svg";
import Icon3 from "../../../assets/hero-section/Group.svg";
import Icon4 from "../../../assets/hero-section/lecturer-with-screen-svgrepo-com 1.svg";

const DataSummary = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const { t } = useTranslation();
  const fetchStatistics = () => {
    const url = `/statistics`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery(
    "getAllCourses",
    fetchStatistics,

    {
      onSuccess() {
        // console.log(data?.data?.data?.data?.user_total);
      },
      onError(error) {
        console.error(error);
      },
    }
  );

  const [Counter, setCounter] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounter(true)}
      onExit={() => setCounter(false)}
    >
      <div className="datasummary  mt-[100px] w-full md:h-[40vh] h-full overflow-hidden">
        <div className="flex justify-center items-center flex-wrap  lg:my-8">
          <div
            className=" block max-w-sm p-6 border border-gray-50 rounded-lg shadow h-[200px] my-2 md:mx-6 lg:w-[20%] w-[40%] mx-2"
            data-aos="fade-up-left"
          >
            <span>
              <img src={Icon1} className="h-20 mx-auto" />
            </span>
            <h5 className="my-2 md:text-[20px]  text-center font-bold tracking-tight text-white ">
              {t("Accreditation guarantee")}
            </h5>
          </div>

          <div
            className="block max-w-sm p-6 border border-gray-50 rounded-lg shadow h-[200px] my-2 md:mx-6 lg:w-[20%] w-[40%] mx-2"
            data-aos="fade-up-right"
          >
            <span>
              <img src={Icon2} className="h-20 mx-auto" />
            </span>
            <h5 className="text-center  my-2  md:text-[20px] text-[13px] md:mx-auto font-bold tracking-tight text-white ">
              {t("Student Number")}
            </h5>
            <p className="text-center text-white font-bold text-[20px]">
              {Counter && (
                <CountUp
                  start={0}
                  end={data?.data?.data?.data?.user_total}
                  duration={2.75}
                />
              )}
            </p>
          </div>

          <div
            className="block max-w-sm p-6 relative border border-gray-50 rounded-lg shadow h-[200px] my-5  md:mx-6 lg:w-[20%] w-[40%] mx-2"
            data-aos="fade-down-left"
          >
            <span>
              <img src={Icon3} className="h-20 mx-auto" />
            </span>
            <h5 className="absolute left-0 text-center w-full my-2 md:text-[20px] text-[14px] font-bold tracking-tight text-white ">
              {t("num of certificates")}
            </h5>
            <p className="mt-[50px] text-center text-white font-bold text-[20px]">
              {Counter && (
                <CountUp
                  start={0}
                  end={data?.data?.data?.data?.Certificate}
                  duration={2.75}
                />
              )}
            </p>
          </div>

          <div
            className="block max-w-sm p-6 border border-gray-50 rounded-lg shadow h-[200px] md:mx-6 lg:w-[20%] w-[40%] mx-2"
            data-aos="fade-down-right"
          >
            <span>
              <img src={Icon4} className="h-20 mx-auto" />
            </span>
            <h5 className="my-2 md:text-[20px] text-center font-bold tracking-tight text-white">
              {t("experience")}
            </h5>
            <p className="text-center text-white font-bold text-[20px]">
              {Counter && (
                <CountUp
                  start={0}
                  end={data?.data?.data?.data?.trainer_total}
                  duration={2.75}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default DataSummary;

import { useEffect, useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa6";

function Btnmode() {


    const [theme, setTheme] = useState(localStorage.getItem("currentmode") ?? "dark");

    useEffect(() => {
        if (theme === "light") {
            document.body.classList.remove("dark");
            document.body.classList.add("light");
        } else {
            document.body.classList.remove("light");
            document.body.classList.add("dark");
        }

    }, [theme])

    return (
        <>
            <button className=" w-12 h-12  border-2 border-[#2976A6] text-[#2180A6]
      rounded-md  justify-center items-center cursor-pointer flex "
                onClick={() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                    
                    localStorage.setItem("currentmode", theme === "dark" ? "light" : "dark")

                    setTheme(localStorage.getItem("currentmode"))

                }}
            >

                {theme === "dark" ? (<FaMoon size={30} className="Icon-moon" />) : (<FaSun size={30} className="Icon-sun" />)}
            </button >
        </>
    )
}
export default Btnmode;


export default function BannarExams() {
  return (
    <div className="ExamsPage w-[95%] md:w-[80%] h-[30vh] md:h-[50vh] mt-[40px] mx-auto rounded-lg">
      <div className=''>
        <div className="w-[50%] mx-auto ">
          {/* <div className="py-16 md:py-40">
            <Title text={"our exams"} items="items-center" />
            <p className="text-white text-center">text</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
const Pagination = ({
  pageCount,
  onPageChange,
  containerClassName,
  previousLinkClassName,
  nextLinkClassName,
  disabledClassName,
  activeClassName,
  breakLabel,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <motion.div initial={{ opacity: 0, y: 400 }} animate={{ opacity: 1, y: 0 }}>
      <ReactPaginate
        previousLabel={
          i18n.language === "ar" ? (
            <IoIosArrowForward size={15} />
          ) : (
            <IoIosArrowBack size={15} />
          )
        }
        nextLabel={
          i18n.language === "ar" ? (
            <IoIosArrowBack size={15} />
          ) : (
            <IoIosArrowForward size={15} />
          )
        }
        pageCount={pageCount}
        onPageChange={onPageChange}
        containerClassName={containerClassName}
        previousLinkClassName={previousLinkClassName}
        nextLinkClassName={nextLinkClassName}
        disabledClassName={disabledClassName}
        activeClassName={activeClassName}
        breakLabel={breakLabel}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        className="flex justify-center items-center"
      />
    </motion.div>
  );
};

export default Pagination;

import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const StudentCard = ({ data, trainerName, courseName, courseId }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <motion.div initial={{ opacity: 0, x: 400 }} animate={{ opacity: 1, x: 0 }}>
      <div
        className="w-[260px]   rounded-lg "
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        {/* image card */}
        <img
          src={data?.image}
          alt=""
          className="w-full h-[180px] object-fit rounded-t-lg"
        />

        {/* content card */}
        <div
          className={`px-4 py-4 relative h-[280px] ${
            isArabic ? "skewArStu" : "skewEnStu"
          }`}
        >
          <div className="pb-2 ">
            <div className="flex items-center gap-x-3">
              <p className="font-bold text-[#0298b1]">{t("student name")} : </p>
              <span className="text-card-std text-black opacity-90 font-bold text-lg">
                {data?.name}
              </span>
            </div>

            <p className="text-[#0298b1] flex flex-col items-center p-2 border shadow-sm font-bold text-center max-h-[80px] min-h-[60px]">
              {t("with instructor")}
              <span className="text-card-std text-base text-black font-semibold ">
                {trainerName}
              </span>
            </p>
            <p className="text-[#0298b1] flex flex-col items-center my-2 p-2 border shadow-sm font-bold text-center max-h-[120px] min-h-[90px]">
              {t("course name")}
              <span className="text-card-std text-base text-black font-semibold ">
                {courseName}
              </span>
            </p>
          </div>

          <Link
            to={`/student-business-details/${courseId}`}
            className="btn-card text-white rounded-md block w-[120px] text-center py-2 mt-2 mx-auto text-[14px]"
          >
            {t("more details")}
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default StudentCard;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEarthAmericas } from "react-icons/fa6";

import { IoLanguageSharp } from "react-icons/io5";

const Language = () => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("English"); // Set initial state to "English"
  const [active, setActive] = useState(selectedOption);
  const { i18n } = useTranslation();
  const openRef = useRef();

  // functions of toggle dropdown
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const handleMousedownEvent = (e) => {
      if (openRef.current && !openRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleMousedownEvent);
    return () => {
      document.removeEventListener("mousedown", handleMousedownEvent);
    };
  }, []);

  // function of select language
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpen(false);
    setActive(option);
    i18n.changeLanguage(option === "العربيه" ? "ar" : "en");
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <div className="relative" ref={openRef}>
      <div
        className="icon-language w-12 h-12  border-2 border-[#2976A6] text-[#2180A6]
      rounded-md  justify-center items-center cursor-pointer flex "
        onClick={handleToggle}
      >
        <FaEarthAmericas size={30} className=" text-[#2180A6]" />
      </div>

      {open ? (
        <div
          className="absolute w-28 h-auto border-2 border-[#2976A6] rounded-md mt-1  cursor-pointer
        flex flex-col items-center justify-center z-50"
        >
          <p
            className={`language-ar mb-1 w-full  py-2 text-center ${
              active === "العربيه" ? " bg-[#2976A6] text-white" : " bg-white"
            }`}
            onClick={() => handleOptionClick("العربيه")}
          >
            العربيه
          </p>
          <p
            className={`language-en
          w-full  py-2 text-center ${
            active === "English" ? " bg-[#2976A6] text-white" : "bg-white"
          }`}
            onClick={() => handleOptionClick("English")}
          >
            English
          </p>
        </div>
      ) : null}

      {/* {i18n.language === "en" ? (
        <button
          className="rounded border-2 border-[#9e72fb] py-1 px-3 block md:hidden"
          onClick={() => {
            i18n.changeLanguage("ar");
          }}
        >
          AR
        </button>
      ) : (
        <button
          className="mr-2 rounded border-2 border-[#9e72fb] py-1 px-3 "
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        >
          EN
        </button>
      )} */}
    </div>
  );
};

export default Language;

import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "../../../../assets/DataIndex";
import AOS from "aos";
import "aos/dist/aos.css";
import { request } from "../../../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../../core/Spinner";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const UserComment = () => {
  const { t } = useTranslation();
  const [comment, setComment] = useState([]);

  const constraintsRef = useRef(null);

  useEffect(() => {
    AOS.init();
  }, []);

  // fetch comments
  const fetchComments = () => {
    const url = "/Opinions";
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery("getComments", fetchComments, {
    onSuccess() {
      setComment(data?.data?.data);
      console.log(comment);
    },
  });

  useEffect(() => {
    refetch();
  }, [comment]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <p className="text-center text-[#0298b1] text-3xl font-bold mt-14 mb-7">
        {t("Ratings")}
      </p>
      {/* <div className="flex mt-[20px] overflow-x-auto w-screen"></div> */}
      <div className=" md:flex md:justify-center">
        {Array.isArray(comment) &&
          comment.map((user) => {
            return (
              <>
                <div
                  key={user?.id}
                  data-aos="flip-right"
                  className="cards mt-5 rounded-2xl text-white p-6 md:mx-1 w-[80%] mx-auto md:w-[400px] h-[170px]"
                >
                  <div className="flex items-center gap-x-2">
                    <p className="font-bold">{user?.name}</p>
                  </div>
                  <span className="text-[14px] font-[400] text-center">
                    {user?.opinion}
                  </span>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default UserComment;

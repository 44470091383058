import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MyAccount from "../components/profile/MyAccount";
import MyCourses from "../components/profile/MyCourses";
import MyCertification from "../components/profile/MyCertification";

const Profile = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState(1);
  const [active, setActive] = useState("my account");
  const components = {
    1: <MyAccount />,
    2: <MyCourses />,
    3: <MyCertification />,
  };

  return (
    <div className="px-4 md:px-20 ">
      <div className=" border-b-2 ">
        <p className=" text-2xl font-bold text-[#0298b1]">{t("account settings")}</p>
      </div>
      <div className="bg-[#F7F8FB] rounded-xl mt-3 ">
        <div className=" flex items-center gap-x-3 border-b-2">
          {["my account", "my courses", "my certifications"].map(
            (item, index) => {
              return (
                <span
                  key={index}
                  className={`font-semibold mx-1 md:mx-5  cursor-pointer text-sm text-[base] ${
                    active === item
                      ? "text-[#0298b1] font-bold border-b-4 border-[#0298b1] py-4"
                      : "text-[#6A7A99]"
                  }`}
                  onClick={() => {
                    setKey(index + 1);
                    setActive(item);
                  }}
                >
                  {t(item)}
                </span>
              );
            }
          )}
        </div>

        {components[key]}
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";

import SocialIcons from "./SocialIcons";

import { request } from "../../utils/axios";
import { useQuery } from "react-query";

import Logo from "../../../assets/top-header/Logo-Footer.svg";
import SocialIcon from "../home/SocialIcon";

const navLinks = [
  { name: "home", route: "/" },
  {
    name: "exams",
    route: "/exams",
  },
  { name: "courses", route: "/courses" },
  { name: "experience", route: "/our-expert" },
  { name: "us", route: "/about-us" },
  { name: "contact", route: "/contact-us" },
];

const Footer = () => {
  //  =================== fetchData ===================
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";
  const fetchData = () => {
    return request({
      url: `/Contact/information?lang=${i18n.language || lang}`,
    });
  };
  const { data } = useQuery("getContactInfo", fetchData, {
    onSuccess: () => {
      // console.log(data?.data?.data[0]?.address);
    },
  });

  const addressData = [
    {
      title: "address",
      disc: data?.data?.data[0]?.address,
      url: "https://www.google.com/maps",
      icon: FaLocationDot,
    },
    {
      title: "phone",
      disc: data?.data?.data[0]?.phone,
      url: "https://wa.me/+201029126073",
      icon: FaPhone,
    },
    {
      title: "email",
      disc: data?.data?.data[0]?.Email,
      url: "https://gmail.com",
      icon: IoMail,
    },
  ];

  // =================================
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  return (
    <footer>
      <div className="bg-[#00529b] h-full">
        <div className="w-full px-2 lg:px-8  py-6  text-white ">
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/*=======================  Col 1 ======================= */}
            <div className="flex flex-col justify-center  items-center md:items-start gap-y-5 w-full lg:w-full  my-8 lg:my-0">
              <div className="w-full">
                <img src={Logo} className="mb-[-20px] mx-auto" />
              </div>
              <p className="w-[90%] md:w-3/4 mx-auto  text-[14px] md:text-sm leading-6 text-center ">
                {t("our main goal")}
              </p>
              <div className="flex items-center justify-center mt-8 w-full ">
                <SocialIcon />
              </div>
            </div>

            {/* ======================= col 2 =======================  */}
            <div className=" flex flex-col justify-center  items-center md:items-start gap-y-5 w-full lg:w-full  my-8 lg:my-0 ">
              <h2 className="flex items-center gap-x-2 font-bold w-[90%] md:w-[50%] mx-auto lg:w-full lg:mx-0">
                <span className="block w-[3px] h-8 bg-white rounded"></span>
                {t("important services of the  development skills platform")}
              </h2>
              <ul className="flex flex-col justify-center mx-auto lg:mx-10">
                {navLinks.map((link, index) => (
                  <li
                    key={index}
                    className="mx-2 lg:mx-4 cursor-pointer list-disc"
                  >
                    <Link
                      to={link.route}
                      className={` font-medium text-[12px]`}
                    >
                      {t(link.name)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* ======================= col 3 =======================  */}
            <div className=" flex flex-col justify-center  items-center md:items-start gap-y-5 w-full lg:w-full  my-8 lg:my-0 ">
              <div className=" w-full pt-8">
                <h2 className="flex items-center gap-x-2 font-bold w-[90%] md:w-[50%] mx-auto  lg:w-full lg:mx-0">
                  <span className="block w-[3px] h-8 bg-white rounded"></span>
                  {t("Contact us information")}
                </h2>
                <div className="flex flex-col gap-y-4  w-full mx-auto lg:ml-5 mt-5 ">
                  {addressData.map((address, index) => {
                    const IconComponent = address.icon;
                    return (
                      <div
                        key={index}
                        className="flex items-center w-[50%] md:w-[20%] lg:w-full mx-auto text-center gap-x-2 "
                      >
                        <IconComponent
                          size={30}
                          className="w-8 h-8 p-1  rounded-md bg-white  text-[#00529b]"
                        />
                        <div>
                          {address.url ? (
                            <a
                              href={address.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{t(address.title)}</p>
                              <span className="text-sm">{address.disc}</span>
                            </a>
                          ) : (
                            <>
                              <p>{t(address.title)}</p>
                              <span className="text-sm">{address.disc}</span>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/*=======================  col 3 ======================= */}
          </div>
        </div>
      </div>
      <p className="text-footer-end text-center text-xs sm:text-base ">
        {t("rights")}
      </p>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import InstructorCard from "../components/courseDetails/InstructorCard";
import CourseInfo from "../components/courseDetails/CourseInfo";

import { useTranslation } from "react-i18next";
import DropCourseDetails from "../components/courseDetails/DropCourseDetails";
import SocialIcons from "../components/core/SocialIcons";
import DataSummary from "../components/socialInterAction/DataSummary ";
import SendEvaluation from "../components/socialInterAction/UserOpinions/SendEvaluation";
import UserComment from "../components/socialInterAction/UserOpinions/UserComment";
import CourseDesc from "../components/courseDetails/CourseDesc";
import { useParams } from "react-router-dom";
import { request } from "../utils/axios";
import { useQuery } from "react-query";
import Spinner from "../components/core/Spinner";

const courseInfo = ["over view", "course details"];
const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState({});

  const [active, setActive] = useState("over view");
  const [showDesc, setShowDesc] = useState(false);
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const handleShowDesc = (item) => {
    setActive(item);
    setShowDesc(item === "over view");
  };

  // handle fetch course details
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/Course/about/${id}?lang=${i18n.language || lang}`;
    return request({ url });
  };

  const { isLoading, data, refetch } = useQuery("getCourseDetails", fetchData, {
    onSuccess() {
      // console.log(data?.data?.data?.Course);
      setCourseDetails(data?.data?.data?.Course);
    },
  });

  useEffect(() => {
    refetch();
  }, [i18n.language , refetch]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="px-8 md:px-16 lg:grid grid-cols-12 gap-x-4 mb-12 ">
        {/* <img
          src={data?.data?.data?.Course?.image}
          alt="course_image"
          className=" w-full h-[450px]  rounded-lg col-span-9
        grid grid-cols-2 md:grid-cols-2 gap-x-2  gap-y-4 object-cover"
        /> */}

        <iframe
          src={data?.data?.data?.Course?.demo_video}
          title="description"
          className=" w-full h-[450px]   rounded-lg col-span-9
          grid grid-cols-2 md:grid-cols-2 gap-x-2  gap-y-4 "
        ></iframe>

        <div className="col-span-3">
          <InstructorCard
            courseId={data?.data?.data?.Course?.id}
            instructorData={data?.data?.data?.Course?.trainer}
          />
        </div>
      </div>
      <div className=" flex justify-center items-center gap-x-4 flex-wrap ">
        <CourseInfo courseDetails={data?.data?.data?.Course} />
      </div>


      <div className="flex justify-center items-center gap-x-4 my-12 px-8">
        {courseInfo.map((item, index) => (
          <span
            key={index}
            className={`md:w-80 w-60 rounded-lg text-center cursor-pointer py-1 ${
              active === item
                ? "  bg-[#F5F4F4] font-bold"
                : "bg-[#00529b] text-white"
            }`}
            onClick={() => {
              handleShowDesc(item);
            }}
          >
            {t(item)}
          </span>
        ))}
      </div>

      <div className="px-8 md:px-32 ">
        {active === "over view" ? (
          <CourseDesc courseDetails={data?.data?.data?.Course} />
        ) : (
          <DropCourseDetails courseDetails={data?.data?.data?.Course} />
        )}
      </div>

      <div className="text-social flex justify-center items-center gap-x-2 my-8">
        {t("you can share course via")}
        <SocialIcons />
      </div>
      <DataSummary />
      <SendEvaluation />
      <div className="flex flex-wrap justify-center items-center gap-x-4  px-8 lg:px-16 mt-12">
        <UserComment />
      </div>
    </div>
  );
};

export default CourseDetails;

import React from "react";
// import { Expert1 } from "../../../assets/DataIndex";
import { useNavigate } from "react-router-dom";

const ExpertCard = ({ item }) => {
  const navigate = useNavigate();
  const { image, name, speciality, id } = item;

  const navigateToExpertDetails = () => {
    navigate(`/expert-details/${id}`);
  };
  return (
    <div
      className="relative w-fit cursor-pointer mt-8 "
      onClick={navigateToExpertDetails}
    >
      <img
        src={image}
        alt="expert_image"
        className="w-32 h-32 rounded-full absolute bottom-[100%] left-[5%] transform translate-x-[50%] translate-y-[50%]"
      />
      <div className="pt-16 pb-2 px-8  w-72 bg-[#00529b] text-white rounded-3xl text-center min-h-40">
        <h5 className="font-bold my-2">{name}</h5>
        <p className="text-sm">{speciality}</p>
      </div>
    </div>
  );
};

export default ExpertCard;

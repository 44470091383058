import React from "react";

import FacebookIcon from "../../../assets/sociallicon/facebook-svgrepo-com.svg";
import Linkedin from "../../../assets/sociallicon/linkedin-svgrepo-com.svg";
import InstgramIcon from "../../../assets/sociallicon/instagram-1-svgrepo-com.svg";

export default function SocialIcon() {
  return (
    <div className="absolute ">
      <div className="flex justify-center">
        <a href="https://www.facebook.com/share/p/HN8zyiC9GP2yGVb7/?mibextid=xfxF2i">
          <img src={FacebookIcon} className="w-10" />
        </a>
        <i className="mx-4">
          <img src={Linkedin} className="w-10" />
        </i>
        <a href="https://www.instagram.com/tsd2379?igsh=MTd0cjY0MWV3a2twcQ==">
          <img src={InstgramIcon} className="w-10 " />
        </a>
      </div>
    </div>
  );
}

import React from "react";
import TopHeader from "./TopHeader";
import NavLinks from "./NavLinks";
import { Outlet } from "react-router-dom";
import "../../../App.css"
const Navbar = () => {
  return (
    <div className="flex flex-col justify-center items-center px-4  lg:px-16 ">
      {/* <TopHeader /> */}
      <NavLinks />

    </div>
  );
};

export default Navbar;

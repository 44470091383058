import React from "react";
import { Clock, Location, Phone } from "../../../assets/DataIndex";

import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

const ContactCard = () => {
  const { i18n, t } = useTranslation();

  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "ar";

  const fetchData = () => {
    const url = `/Contact/information?lang=${i18n.language || lang}`;
    return request({ url });
  };

  const { isLoading, data, refetch, isError } = useQuery(
    "getAboutUs",
    fetchData,
    {
      onSuccess() {
        // setQuestions(data?.data?.data);
        // console.log(data?.data?.data);
      },
      onError(e) {
        console.log(e.message);
      },
    }
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error fetching data</p>;
  }
  return (
    <>
      <div className="w-[290px] p-4 bg-[#00529b] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Clock} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg text-white">
            {t("service available")}
          </span>
          <p className="text-balck font-bold">5:00 م - 8:00 ص</p>
        </div>
      </div>

      <div className="w-[290px] p-4 bg-[#00529b] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Location} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg text-white">{t("address")}</span>
          <p className="text-balck font-bold">
            {data?.data?.data?.[0]?.address}
          </p>
        </div>
      </div>

      <div className="w-[290px] p-4 bg-[#00529b] rounded-lg flex items-center gap-x-3 mt-3">
        <div className="w-14 h-14 rounded-full bg-white p-2  flex items-center justify-center">
          <img src={Phone} alt="book" className="text-center" />
        </div>
        <div className="">
          <span className="font-bold text-lg text-white">{t("phone")}</span>
          <p className="text-balck font-bold">{data?.data?.data?.[0]?.phone}</p>
        </div>
      </div>
    </>
  );
};

export default ContactCard;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// i18n
import "./i18n";
// react router dom
import { BrowserRouter } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import { store } from "./actions/redux/Store";
// context
import PopupProvider from "./actions/context/PopupProvider";
// query
import { QueryClientProvider, QueryClient } from "react-query";
import CommentProvider from "./actions/context/CommentProvider";
import { Toaster } from "react-hot-toast";
import ContactProvider from "./actions/context/ContactProvider";



const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Toaster position="top-center" reverseOrder={false} />
    <PopupProvider>
      <BrowserRouter>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ContactProvider>
              <CommentProvider>
                <App />
              </CommentProvider>
            </ContactProvider>
          </QueryClientProvider>
        </Provider>
      </BrowserRouter>
    </PopupProvider>
  </React.StrictMode>
);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainBtn from "../core/MainBtn";
import { useContactContext } from "../../context/ContactProvider";
import toast from "react-hot-toast";

const EmailContact = () => {
  const { t } = useTranslation();
  const { submitOpinion } = useContactContext();
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const phone = event.target.elements.phone.value;
    const message = event.target.elements.message.value;

    // Validation for phone input
    if (phone.length !== 11) {
      setPhoneError(t("phone validation error"));
      return;
    } else {
      setPhoneError("");
    }

    // Validation for message textarea
    if (message.length < 40) {
      setMessageError(t("message validation error"));
      return;
    } else {
      setMessageError("");
    }

    try {
      await submitOpinion(phone, message);
      event.target.reset();
      toast.success(t("success send"));
    } catch (error) {
      console.error("Error submitting opinion:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-y-12 mt-24">
      <span className="text-center text-[#0298b1] text-3xl font-bold">
        {t("Communicate by email")}
      </span>
      <p className="text-sm text-contactus">{t("customer service")}</p>
      <form onSubmit={handleFormSubmit}>
        <div className="flex flex-col items-center gap-y-4">
          <input
            type="tel"
            className={`border border-black rounded-2xl w-[320px] md:w-[450px] h-8 px-2 placeholder:text-[12x] outline-none ${
              phoneError && "border-red-500"
            }`}
            placeholder={t("phone")}
            name="phone"
            required
          />
          {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
          <textarea
            name="message"
            id="message"
            className={`border border-black rounded-2xl w-[320px] md:w-[450px] h-[200px] p-2 placeholder:text-[12x] outline-none ${
              messageError && "border-red-500"
            }`}
            placeholder={`${t("leave the message here")}........`}
            required
          ></textarea>
          {messageError && (
            <p className="text-red-500 text-sm">{messageError}</p>
          )}
          <MainBtn text={t("send")} />
        </div>
      </form>
    </div>
  );
};

export default EmailContact;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import FormModel from "../../components/auth/FormModel";
import AuthInput from "../../components/auth/AuthInput";

import FormButton from "../../components/core/FormBtn";
import { FormProvider, useForm } from "react-hook-form";
import {
  email_validation,
  password_validation,
} from "../../utils/auth/inputValidations";
import { usePopupContext } from "../../context/PopupProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/auth/authActions";
import Register from "./Register";
import ForgetPass from "./ForgetPass";
import Spinner from "../../components/core/Spinner";

import { toast } from "react-hot-toast";
const Login = () => {
  const { t } = useTranslation();
  const loginRef = useRef();
  const { closeLogin, openRegister, showRegister, showForget, openForget } =
    usePopupContext();
  const methods = useForm();


  useEffect(() => {
    loginRef.current && loginRef.current.focus();
  }, []);

  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  if (loading) {
    return <Spinner />;
  }
  const onSubmit = methods.handleSubmit(async (data) => {
    dispatch(userLogin(data));
    methods.reset();
    toast.success(t("successfully login"));
    closeLogin();
    setTimeout(() => {
      window.location.reload(false);
    }, 3000);
  });

  return (
    <>

      <FormProvider {...methods}>
        <FormModel backFun={closeLogin}>
          <div className="text-center flex flex-col items-center justify-between ">
            <h2 className="text-3xl font-semibold mt-6 mb-1">{t("hello")}</h2>
            <span className="text-lg mb-5">{t("login ways")}</span>
          </div>

          {/* <p className="text-lg font-semibold my-6 text-center relative">
           
            <span className="before:block before:content-[''] before:w-[9rem] before:h-px before:bg-slate-300 absolute top-1/2 left-6 transform -translate-y-1/2"></span>
            <span className="after:block after:content-[''] after:w-[9rem] after:h-px after:bg-slate-300 absolute top-1/2 right-6 transform -translate-y-1/2"></span>
          </p> */}

          <form
            onSubmit={(e) => e.preventDefault()}
            noValidate
            autoComplete="off"
          >
            {error && <p className="text-red-500 text-center">{t("error")}</p>}
            {success && !userInfo && (
              <p className="text-green-500 text-center">{t("login now")}</p>
            )}

            <div className="flex justify-center">
              <div>
                <AuthInput {...email_validation} Icon={MdOutlineEmail} />
                <AuthInput {...password_validation} Icon={CiLock} />
              </div>
            </div>

            <button
              className="text-center text-[black] my-4 "
              onClick={openForget}
            >
              {t("forget pass")}
            </button>

            {showForget && <ForgetPass />}
            <FormButton text={t("loginbtn")} submit={onSubmit} />
          </form>

          <div>
            <p className="text-center">
              {t("create account")}
              <button
                className="text-[#726DF1] cursor-pointer underline"
                onClick={openRegister}
              >
                {t("new account")}
              </button>
              {showRegister && <Register />}
            </p>
          </div>
        </FormModel>
      </FormProvider>
      {/* )} */}
    </>
  );
};

export default Login;

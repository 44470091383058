import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { request } from "../../utils/axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Spinner from "../core/Spinner";

const SelectCity = ({ isLoadingCities, citiesData, onSelectCity }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpen(false);
    onSelectCity(option.id);
    // console.log("city id:", option.id);
  };

  return (
    <div className="mb-2">
      <div
        className="w-[290px] md:w-[360px] border border-[#c8c7cc] rounded-lg  py-3 px-3  flex justify-between items-center"
        onClick={handleOpen}
      >
        {selectedOption
          ? i18n === "en"
            ? selectedOption.city_en
            : selectedOption.city_ar
          : t("select an city")}
        <FaAngleDown className="text-[#2180a6]" />
      </div>
      {open && (
        <div
          className=" 
         max-h-52 overflow-y-scroll
        w-[290px] md:w-[360px] shadow rounded-lg py-3 px-3 my-[2px]"
        >
          {isLoadingCities ? (
            <Spinner />
          ) : (
            citiesData.map((city) => (
              <div
                key={city?.id}
                onClick={() => handleOptionClick(city)}
                className="cursor-pointer hover:bg-gray-200 py-1 "
              >
                {i18n === "en" ? city?.city_en : city?.city_ar}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SelectCity;
